const _jsxFileName = "/home/runner/work/asew-rechnungserklaerer-web/asew-rechnungserklaerer-web/src/js/components/HeaderLogoComponent.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import * as React from "react";
import { useAppState } from "../state";
import styled from "styled-components";

const Logo = styled.img`
  max-height: 70px;
  padding: 7px 0;
  margin-top: 5px;
  @media only screen and (max-width: 1240px) {
    padding: 7px 0;
    max-height: 45px;
    margin-top: 20px;
  }
`;

export const HeaderLogoComponent = React.memo(function HeaderLogoComponent() {
  const { tenantConfig } = useAppState();

  return (
    React.createElement(Logo, {
      style: {
        height: "100%",
        float: "right",
        cursor: "pointer",
      },
      onClick: () => {
        if (_optionalChain([tenantConfig, 'optionalAccess', _ => _.get, 'call', _2 => _2("logoUrl")])) {
          _optionalChain([window, 'access', _3 => _3.open, 'call', _4 => _4(_optionalChain([tenantConfig, 'optionalAccess', _5 => _5.get, 'call', _6 => _6("logoUrl")]), "_blank"), 'optionalAccess', _7 => _7.focus, 'call', _8 => _8()]);
        }
      },
      src: _optionalChain([tenantConfig, 'optionalAccess', _9 => _9.get, 'call', _10 => _10("logo"), 'optionalAccess', _11 => _11.url, 'call', _12 => _12()]) || "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
    )
  );
});

const _jsxFileName = "/home/runner/work/asew-rechnungserklaerer-web/asew-rechnungserklaerer-web/src/js/components/GlossaryRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import * as React from "react";




import {
  Collapse,
  Space,
  Anchor,
  Input,


  Empty,
  Button,
  Typography,
  Affix,
} from "antd";

import { useAppState } from "../state";

import { AppLayout } from "./AppLayout";

import { GlossaryEntryDetails } from "./GlossaryEntryDetails";

import { useTranslation } from "@opendash/i18n";
import { Icon } from "@opendash/icons";

const { Panel } = Collapse;
const { Link } = Anchor;
const { Search } = Input;
const { Title } = Typography;
var lastLetter = "";
var searchEmpty = false;

const LETTERS = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "#",
];

export const GlossaryRoute = React.memo(function GlossaryRoute() {
  const { glossaryEntries } = useAppState();
  const t = useTranslation();

  const [search, setSearch] = React.useState("");
  const [isAffix, setAffix] = React.useState(false);
  const [isAffixSearching, setAffixSearching] = React.useState(false);

  React.useEffect(() => {
    if (!isAffix && isAffixSearching) {
      setAffixSearching(false);
    }
  }, [isAffix, isAffixSearching]);

  const groups = React.useMemo(() => {
    const entries = !search
      ? glossaryEntries
      : glossaryEntries.filter((e) =>
          _optionalChain([e, 'access', _ => _.get, 'call', _2 => _2("title"), 'optionalAccess', _3 => _3.toLowerCase, 'call', _4 => _4(), 'access', _5 => _5.includes, 'call', _6 => _6(search.toLowerCase())])
        );

    const groups = Object.fromEntries(
      LETTERS.map((letter) => [letter, []])
    );

    for (const entry of entries) {
      const letter = entry.get("title").charAt(0).toUpperCase();

      if (LETTERS.includes(letter)) {
        groups[letter].push(entry);
      } else if (letter === "Ä") {
        groups["A"].push(entry);
      } else if (letter === "Ü") {
        groups["U"].push(entry);
      } else if (letter === "Ö") {
        groups["O"].push(entry);
      } else {
        groups["#"].push(entry);
      }
    }

    return Object.entries(groups);
  }, [glossaryEntries, search]);

  return (
    React.createElement(AppLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 110}}
      /* Page Header */
      , React.createElement('div', { style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 112}}
        , React.createElement(Title, { level: 3, __self: this, __source: {fileName: _jsxFileName, lineNumber: 113}}, t("app:glossar.title"))
      )

      /* Nav & Search */
      , React.createElement(Affix, { offsetTop: 80, onChange: (x) => setAffix(x || false), __self: this, __source: {fileName: _jsxFileName, lineNumber: 117}}
        , React.createElement('div', {
          style: {
            padding: "20px 10px",
            width: "100%",
            justifyContent: "center",
            textAlign: "center",

            // TODO!? @Nico schön machen
            ...(isAffix && {
              background: "#f0f2f5",
              boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            }),
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 118}}
        
          , !isAffixSearching && (
            React.createElement(Anchor, {
              affix: false,
              offsetTop: 220,
              style: {
                width: "100%",
                justifyContent: "center",
                textAlign: "center",
                display: "flex",

                ...(!isAffix && {
                  marginBottom: 20,
                }),
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 133}}
            
              , LETTERS.map((X) => (
                React.createElement(Link, {
                  title: X,
                  key: X,
                  href: "#" + (X !== "#" ? X : lastLetter), __self: this, __source: {fileName: _jsxFileName, lineNumber: 148}}
                , " "

                )
              ))

              , isAffix && (
                React.createElement(Button, {
                  type: "text",
                  icon: React.createElement(Icon, { icon: "fa:search", __self: this, __source: {fileName: _jsxFileName, lineNumber: 160}} ),
                  onClick: () => {
                    setAffixSearching(true);
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 158}}
                )
              )
            )
          )
          , React.createElement('div', {
            style: {
              display: !isAffix || isAffixSearching ? "block" : "none",
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 168}}
          
            , React.createElement(Search, {
              id: "searchglossar",
              placeholder: t("app:glossar.search"),
              size: "large",
              allowClear: true,
              style: {
                width: "calc(100% - 50px)",
                maxWidth: 700,
              },
              onSearch: (value) => {
                setSearch(value);
              },
              onChange: (e) => {
                if (e.target.value === "") {
                  setSearch("");
                }
              },
              enterButton: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 173}}
            )

            , isAffixSearching && (
              React.createElement(Button, {
                type: "text",
                style: {
                  marginLeft: 10,
                },
                icon: 
                  React.createElement(Icon, {
                    icon: "fa:times",
                    style: {
                      fontSize: 32,
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 200}}
                  )
                ,
                onClick: () => {
                  setAffixSearching(false);
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 194}}
              )
            )
          )
        )
      )

      /* Content */

      , React.createElement('div', { style: { width: "100%", padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 218}}
        , searchEmpty ? (
          React.createElement('div', { style: { paddingTop: "50px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 220}}
            , React.createElement(Empty, {
              imageStyle: {
                height: 100,
              },
              description: React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 225}}, t("app:glossar.noentry")), __self: this, __source: {fileName: _jsxFileName, lineNumber: 221}}
            
              , React.createElement(Button, {
                type: "primary",
                onClick: () => {
                  setSearch("");
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 227}}
              
                , t("app:glossar.resetsearch")
              )
            )
          )
        ) : (
          React.createElement(Space, { id: "test", direction: "vertical", style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 238}}
            , groups.map(([letter, glossaryEntries]) => (
              React.createElement('div', { key: letter, id: letter, __self: this, __source: {fileName: _jsxFileName, lineNumber: 240}}
                , glossaryEntries.length > 0 && (
                  React.createElement(React.Fragment, null
                    , React.createElement('h1', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 243}}, letter)
                    , React.createElement(Collapse, {
                      style: { width: "100%", fontWeight: "bold" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 244}}
                      // activeKey={glossaryEntries.map((e) => e.id)}
                    
                      , glossaryEntries.map((glossaryEntry) => (
                        React.createElement(Collapse.Panel, {
                          key: glossaryEntry.id,
                          header: 
                            glossaryEntry.get("title").toUpperCase().charAt(0) +
                            " - " +
                            glossaryEntry.get("title")
                          ,
                          children: 
                            React.createElement(GlossaryEntryDetails, { entry: glossaryEntry, __self: this, __source: {fileName: _jsxFileName, lineNumber: 257}} )
                          , __self: this, __source: {fileName: _jsxFileName, lineNumber: 249}}
                        )
                      ))
                    )
                  )
                )
              )
            ))
          )
        )
      )
    )
  );
});

import Parse from "parse";
















export class Attachment extends Parse.Object {
  static __initStatic() {this.className = "APP_Attachment"}

  constructor(data) {
    super("APP_Attachment", data );
  }

  get description() {
    return super.get("description");
  }
  set description(value) {
    super.set("description", value);
  }
  get file() {
    return super.get("file");
  }
  set file(value) {
    super.set("file", value);
  }
  get image() {
    return super.get("image");
  }
  set image(value) {
    super.set("image", value);
  }
  get name() {
    return super.get("name");
  }
  set name(value) {
    super.set("name", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
} Attachment.__initStatic();

Parse.Object.registerSubclass("APP_Attachment", Attachment);

const _jsxFileName = "/home/runner/work/asew-rechnungserklaerer-web/asew-rechnungserklaerer-web/src/js/components/ContactRoute.tsx";import * as React from "react";
import { Typography, } from "antd";
import { AppLayout } from "./AppLayout";
import { ContactContent } from "./ContactContent";
import { useTranslation } from "@opendash/i18n";

const { Title } = Typography;

export const ContactRoute = React.memo(function ContactRoute() {
  const t = useTranslation();

  return (
    React.createElement(AppLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}
      , React.createElement('div', { style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}
        , React.createElement(Title, { level: 3, __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}, t("app:contact.title"))
      )
      , React.createElement(ContactContent, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 17}} )
    )
  );
});

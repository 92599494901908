const _jsxFileName = "/home/runner/work/asew-rechnungserklaerer-web/asew-rechnungserklaerer-web/src/js/components/LegalRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import * as React from "react";
import ReactMarkdown from "react-markdown";

import styled from "styled-components";


import { Typography, } from "antd";

import { useAppState } from "../state";
import { AppLayout } from "./AppLayout";
import { useTranslation } from "@opendash/i18n";


const Container = styled.div`
  background: white;
  padding: 20px;

  min-height: 100%;
`;

const Title = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 800px;

  margin-bottom: 20px;
`;

const Content = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
`;

export const LegalRoute = React.memo(
  function LegalRoute({ scope }) {
    const t = useTranslation();
    const { tenantConfig } = useAppState();

    return (
      React.createElement(AppLayout, { fullWidth: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
        , React.createElement(Container, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}
          , React.createElement(Title, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}
            , React.createElement(Typography.Title, {
              level: 3,
              children: t("app:legal.title", { context: scope }), __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
            )
          )
          , React.createElement(Content, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
            , React.createElement(ReactMarkdown, {
              children: (_optionalChain([tenantConfig, 'optionalAccess', _ => _.get, 'call', _2 => _2(scope)]) ) || "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
            )
          )
        )
      )
    );
  }
);

const _jsxFileName = "/home/runner/work/asew-rechnungserklaerer-web/asew-rechnungserklaerer-web/src/js/components/LanguageControls.tsx";import * as React from "react";

import { Space, Tooltip } from "antd";

import { useCurrentLanguage, changeLanguage } from "@opendash/i18n";
import { app, useAppState } from "../state";
import styled from "styled-components";

const FlagButton = styled.button`
  border: 0;
  outline: 0;
  padding: 0;
  margin: 0;
  display: block;
  height: 18px;
  width: 30px;
  cursor: pointer;
  background: transparent;

  &:disabled {
    cursor: inherit;
  }
`;

export const LanguageControls = React.memo(function LanguageControls() {
  const currentLanguage = useCurrentLanguage();

  const state = useAppState();

  if (state.forceLanguage) {
    return null;
  }

  return (
    React.createElement(Space, { direction: "horizontal", __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}
      , React.createElement(Tooltip, { overlay: "Wähle Deutsch als deine bevorzugte Sprache"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}
        , React.createElement(FlagButton, {
          disabled: currentLanguage === "de",
          onClick: () => {
            changeLanguage("de").then(() => {
              app.init();
            });
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}
        
          , React.createElement('svg', {
            xmlns: "http://www.w3.org/2000/svg",
            width: "30",
            height: "18",
            viewBox: "0 0 5 3"   , __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
          
            , React.createElement('path', { d: "M0 0h5v3H0z" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}} )
            , React.createElement('path', { fill: "#D00", d: "M0 1h5v2H0z" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}} )
            , React.createElement('path', { fill: "#FFCE00", d: "M0 2h5v1H0z" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}} )
          )
        )
      )

      , React.createElement(Tooltip, { overlay: "Select English as your preferred language"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}
        , React.createElement(FlagButton, {
          disabled: currentLanguage === "en",
          onClick: () => {
            changeLanguage("en").then(() => {
              app.init();
            });
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}
        
          , React.createElement('svg', {
            xmlns: "http://www.w3.org/2000/svg",
            viewBox: "0 0 60 30"   ,
            width: "30",
            height: "18", __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}
          
            , React.createElement('clipPath', { id: "a", __self: this, __source: {fileName: _jsxFileName, lineNumber: 73}}
              , React.createElement('path', { d: "M0 0v30h60V0z" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 74}} )
            )
            , React.createElement('clipPath', { id: "b", __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}
              , React.createElement('path', { d: "M30 15h30v15zv15H0zH0V0zV0h30z" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 77}} )
            )
            , React.createElement('g', { clipPath: "url(#a)", __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}
              , React.createElement('path', { d: "M0 0v30h60V0z" , fill: "#012169", __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}} )
              , React.createElement('path', { d: "M0 0l60 30m0-30L0 30"   , stroke: "#fff", strokeWidth: "6", __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}} )
              , React.createElement('path', {
                d: "M0 0l60 30m0-30L0 30"   ,
                clipPath: "url(#b)",
                stroke: "#C8102E",
                strokeWidth: "4", __self: this, __source: {fileName: _jsxFileName, lineNumber: 82}}
              )
              , React.createElement('path', { d: "M30 0v30M0 15h60"  , stroke: "#fff", strokeWidth: "10", __self: this, __source: {fileName: _jsxFileName, lineNumber: 88}} )
              , React.createElement('path', { d: "M30 0v30M0 15h60"  , stroke: "#C8102E", strokeWidth: "6", __self: this, __source: {fileName: _jsxFileName, lineNumber: 89}} )
            )
          )
        )
      )
      , React.createElement(Tooltip, { overlay: "Tercih ettiğiniz dil olarak Türkçe'yi seçin"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 94}}
        , React.createElement(FlagButton, {
          disabled: currentLanguage === "tr",
          onClick: () => {
            changeLanguage("tr").then(() => {
              app.init();
            });
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 95}}
        
          , React.createElement('svg', {
            xmlns: "http://www.w3.org/2000/svg",
            width: "30",
            height: "18",
            viewBox: "0 0 1200 800"   , __self: this, __source: {fileName: _jsxFileName, lineNumber: 103}}
          
            , React.createElement('path', { fill: "#E30A17", d: "M0 0h1200v800H0z" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 109}} )
            , React.createElement('circle', { cx: "425", cy: "400", r: "200", fill: "#fff", __self: this, __source: {fileName: _jsxFileName, lineNumber: 110}} )
            , React.createElement('circle', { cx: "475", cy: "400", r: "160", fill: "#e30a17", __self: this, __source: {fileName: _jsxFileName, lineNumber: 111}} )
            , React.createElement('path', {
              fill: "#fff",
              d: "M583.334 400l180.901 58.779-111.804-153.885v190.212l111.804-153.885z"  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 112}}
            )
          )
        )
      )
      , React.createElement(Tooltip, { overlay: "Выберите русский в качестве предпочитаемого языка"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 119}}
        , React.createElement(FlagButton, {
          disabled: currentLanguage === "ru",
          onClick: () => {
            changeLanguage("ru").then(() => {
              app.init();
            });
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 120}}
        
          , React.createElement('svg', {
            xmlns: "http://www.w3.org/2000/svg",
            viewBox: "0 0 9 6"   ,
            width: "30",
            height: "18", __self: this, __source: {fileName: _jsxFileName, lineNumber: 128}}
          
            , React.createElement('rect', { fill: "#fff", width: "9", height: "3", __self: this, __source: {fileName: _jsxFileName, lineNumber: 134}} )
            , React.createElement('rect', { fill: "#d52b1e", y: "3", width: "9", height: "3", __self: this, __source: {fileName: _jsxFileName, lineNumber: 135}} )
            , React.createElement('rect', { fill: "#0039a6", y: "2", width: "9", height: "2", __self: this, __source: {fileName: _jsxFileName, lineNumber: 136}} )
          )
        )
      )
      , React.createElement(Tooltip, { overlay: "Selectați limba română ca limbă preferată"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 140}}
        , React.createElement(FlagButton, {
          disabled: currentLanguage === "ro",
          onClick: () => {
            changeLanguage("ro").then(() => {
              app.init();
            });
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 141}}
        
          , React.createElement('svg', {
            xmlns: "http://www.w3.org/2000/svg",
            width: "30",
            height: "18",
            viewBox: "0 0 3 2"   , __self: this, __source: {fileName: _jsxFileName, lineNumber: 149}}
          
            , React.createElement('rect', { width: "3", height: "2", x: "0", y: "0", fill: "#002B7F", __self: this, __source: {fileName: _jsxFileName, lineNumber: 155}} )
            , React.createElement('rect', { width: "2", height: "2", x: "1", y: "0", fill: "#FCD116", __self: this, __source: {fileName: _jsxFileName, lineNumber: 156}} )
            , React.createElement('rect', { width: "1", height: "2", x: "2", y: "0", fill: "#CE1126", __self: this, __source: {fileName: _jsxFileName, lineNumber: 157}} )
          )
        )
      )
      , React.createElement(Tooltip, { overlay: "Изберете български като предпочитан език"    , __self: this, __source: {fileName: _jsxFileName, lineNumber: 161}}
        , React.createElement(FlagButton, {
          disabled: currentLanguage === "bg",
          onClick: () => {
            changeLanguage("bg").then(() => {
              app.init();
            });
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 162}}
        
          , React.createElement('svg', {
            xmlns: "http://www.w3.org/2000/svg",
            width: "30",
            height: "18",
            viewBox: "0 0 5 3"   , __self: this, __source: {fileName: _jsxFileName, lineNumber: 170}}
          
            , React.createElement('rect', { width: "5", height: "3", fill: "#fff", __self: this, __source: {fileName: _jsxFileName, lineNumber: 176}} )
            , React.createElement('rect', { width: "5", height: "2", y: "1", fill: "#00966E", __self: this, __source: {fileName: _jsxFileName, lineNumber: 177}} )
            , React.createElement('rect', { width: "5", height: "1", y: "2", fill: "#D62612", __self: this, __source: {fileName: _jsxFileName, lineNumber: 178}} )
          )
        )
      )
      , React.createElement(Tooltip, { overlay: "Виберіть українську як бажану мову"    , __self: this, __source: {fileName: _jsxFileName, lineNumber: 182}}
        , React.createElement(FlagButton, {
          disabled: currentLanguage === "uk",
          onClick: () => {
            changeLanguage("uk").then(() => {
              app.init();
            });
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 183}}
        
          , React.createElement('svg', {
            xmlns: "http://www.w3.org/2000/svg",
            width: "30",
            height: "18",
            viewBox: "0 0 3 2"   , __self: this, __source: {fileName: _jsxFileName, lineNumber: 191}}
          
            , React.createElement('rect', { width: "3", height: "2", fill: "#005BBB", __self: this, __source: {fileName: _jsxFileName, lineNumber: 197}} )
            , React.createElement('rect', { width: "3", height: "1", y: "1", fill: "#FFD500", __self: this, __source: {fileName: _jsxFileName, lineNumber: 198}} )
          )
        )
      )
    )
  );
});

import Parse from "parse";





















export class Video extends Parse.Object {
  static __initStatic() {this.className = "APP_Video"}

  constructor(data) {
    super("APP_Video", data );
  }

  get description() {
    return super.get("description");
  }
  set description(value) {
    super.set("description", value);
  }
  get image() {
    return super.get("image");
  }
  set image(value) {
    super.set("image", value);
  }
  get language() {
    return super.get("language");
  }
  set language(value) {
    super.set("language", value);
  }
  get order() {
    return super.get("order");
  }
  set order(value) {
    super.set("order", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
  get text() {
    return super.get("text");
  }
  set text(value) {
    super.set("text", value);
  }
  get title() {
    return super.get("title");
  }
  set title(value) {
    super.set("title", value);
  }
  get video() {
    return super.get("video");
  }
  set video(value) {
    super.set("video", value);
  }
} Video.__initStatic();

Parse.Object.registerSubclass("APP_Video", Video);

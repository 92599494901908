const _jsxFileName = "/home/runner/work/asew-rechnungserklaerer-web/asew-rechnungserklaerer-web/src/js/components/InvoiceRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import * as React from "react";
import styled from "styled-components";

import { useTranslation } from "@opendash/i18n";
import {
  Col,
  Collapse,
  Divider,
  Empty,
  Modal,
  Pagination,
  Row,
  Select,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { getInvoiceTitle, useAppState } from "../state";

import { useElementSize } from "@opendash/core";
import Title from "antd/lib/typography/Title";
import { AppLayout } from "./AppLayout";
import { GlossaryEntryDetails } from "./GlossaryEntryDetails";
import { InvoiceIcon } from "./InvoiceIcon";
import { VideoPlayer } from "./VideoPlayer";

const { Option } = Select;

const INVOICE_WIDTH = 2480;
const INVOICE_HEIGHT = 3508;

const ContentContainer = styled.div`
  display: flex;
  scroll-behavior: smooth;
  .paddingClass {
    padding: 10px;
  }
  .paddingClassWithoutPadding {
    padding: 10px;
  }

  @media only screen and (max-width: 768px) {
    .paddingClass {
      padding: 0px;
      padding-top: 10px;
    }
    .paddingClassWithoutPadding {
      padding: 0px;
    }
  }

  .btntest {
    position: absolute;
    border-radius: 100%;
    border-top-left-radius: 10px;
    background-color: var(--primaryColor);
    color: var(--primaryTextColor);
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
  }

  @media only screen and (max-width: 1024px) {
    .btntest {
      width: 30px;
      height: 30px;
      font-size: 12px;
    }
  }
  @media only screen and (max-width: 786px) {
    .btntest {
      width: 29px;
      height: 29px;
      font-size: 11px;
    }
  }
  @media only screen and (max-width: 512px) {
    .btntest {
      width: 25px;
      height: 25px;
      font-size: 10px;
    }
  }
`;

const PageContainer = styled.div`
  flex-grow: 0;
  width: 100%;
`;

const ExplainationContainer = styled.div`
  flex-grow: 1;
`;

const PageImageContainer = styled.div`
  position: relative;
  border: 1px solid #d9d9d9;
`;

const PageImageAnnotation = styled.span`
  position: absolute;
  /* left, top and background are calculated */
  height: 40px;

  width: auto;
  min-width: 200px;
  color: black;
  visibility: hidden;

  display: inline-flex;
  border-radius: 25px;

  .iconplacer {
    min-width:40px;
    font-size: 16px;
    font-weight:700,
    border-radius: 25px;
    display: inline-block;
    cursor: pointer;
    position: relative;
    visibility: visible;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-delay: initial;
    transition-property: visibility;
    z-index: 10;
    padding-left: 0px;
    padding-top: 0px;
    margin-top: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    cursor: pointer;
    transition-duration: 0.25s;
    transition-timing-function: ease;
    transition-delay: initial;
    transition-property: transform;
    border-radius: 50%;
    // border-top-left-radius: 10px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    width: 40px;
    height: 40px;
  }

  .iconmarker {
    display: none;
  }

  .iconmarkerr {
    margin-left: -0px;
  }

  @media only screen and (min-width: 768px) {
    :hover {
      .iconmarkerl {
        z-index:9999999;
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        height: 40px;
        padding-top: 10px;
        padding-right: 15px;
        padding-bottom: 10px;
        padding-left: 40px;
        margin-left: -30px;
        font-family: Roboto, Roboto, sans-serif;
        white-space: nowrap;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        border-bottom-left-radius: 25px;
        animation-duration: 0.3s;
        animation-timing-function: ease;
        animation-delay: initial;
        animation-iteration-count: initial;
        animation-direction: initial;
        animation-fill-mode: initial;
        animation-play-state: initial;
        animation-name: initial;
        visibility: visible;
        transition-duration: 0.1s;
        transition-timing-function: ease;
        transition-delay: initial;
        transition-property: visibility;
      }
      .iconmarkerr {
        
        z-index:9999999;
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        height: 40px;
        padding-top: 10px;
        padding-right: 50px;
        padding-bottom: 10px;
        padding-left: 15px;

        font-family: Roboto, Roboto, sans-serif;
        white-space: nowrap;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        border-bottom-left-radius: 25px;
        animation-duration: 0.3s;
        animation-timing-function: ease;
        animation-delay: initial;
        animation-iteration-count: initial;
        animation-direction: initial;
        animation-fill-mode: initial;
        animation-play-state: initial;
        animation-name: initial;
        visibility: visible;
        transition-duration: 0.1s;
        transition-timing-function: ease;
        transition-delay: initial;
        transition-property: visibility;
      }
    }
  }

  

  @media only screen and (max-width: 1024px) {
    .iconplacer {
      min-width: 30px;
      width: 30px;
      height: 30px;
      font-size: 12px;
    }

    :hover {
      .iconmarkerl {
        height: 30px;
        font-size: 12px;
      }
      .iconmarkerr {
        height: 30px;
        font-size: 12px;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .iconplacer {
      min-width: 25px;
      width: 25px;
      height: 25px;
      font-size: 10px;
    }

    :hover {
      .iconmarkerl {
        height: 25px;
        font-size: 10px;
      }
      .iconmarkerr {
        height: 25px;
        font-size: 10px;
      }
    }
  }
`;










export const InvoiceRoute = React.memo(function InvoiceRoute() {
  // const [width, setWidth] = React.useState<number>(window.innerWidth);
  // function handleWindowSizeChange() {
  //   setWidth(window.innerWidth);
  // }
  // React.useEffect(() => {
  //   window.addEventListener("resize", handleWindowSizeChange);
  //   return () => {
  //     window.removeEventListener("resize", handleWindowSizeChange);
  //   };
  // }, []);

  const { width } = useElementSize({ current: document.body }, 100);

  let isMobile = width <= 768;

  const t = useTranslation();
  const {
    invoices,
    invoicePages: invoicePagesAll,
    glossaryEntries,
    tenantConfig,
  } = useAppState();

  const documentLabels = _optionalChain([tenantConfig, 'optionalAccess', _ => _.get, 'call', _2 => _2("documentLabels")]);

  const { invoiceId, pageId } = useParams();

  const [modalStatus, setModalStatus] = React.useState(false);

  const navigate = useNavigate();

  const selectedInvoice = invoices.find((invoice) => invoice.id === invoiceId);

  const invoicePages = React.useMemo(
    () =>
      invoicePagesAll.filter((page) => _optionalChain([page, 'access', _3 => _3.get, 'call', _4 => _4("invoice"), 'optionalAccess', _5 => _5.id]) === invoiceId),
    [invoiceId]
  );

  const selectedPage = React.useMemo(
    () => invoicePages.find((page) => page.id === pageId),
    [invoicePages, pageId]
  );

  const imageHeight = _optionalChain([selectedPage, 'optionalAccess', _6 => _6.get, 'call', _7 => _7("imageHeight")]) || INVOICE_HEIGHT;
  const imageWidth = _optionalChain([selectedPage, 'optionalAccess', _8 => _8.get, 'call', _9 => _9("imageWidth")]) || INVOICE_WIDTH;
  const imageContainerRef = React.useRef(null);
  const imageContainerSize = useElementSize(imageContainerRef, 1000);
  const imageContainerScale = imageContainerSize.width / imageWidth;

  const selectedPageAnnotations = React.useMemo(() => {
    if (!selectedPage) {
      return [];
    }

    const annotations = selectedPage.get("annotations");

    return annotations;
  }, [selectedPage]);

  const selectedPageGlossaryEntries = React.useMemo(() => {
    if (!selectedPage) {
      return [];
    }

    const keywords = selectedPageAnnotations.map(
      (annotation) => annotation.keyword
    );

    return keywords.map((keyword) =>
      glossaryEntries.find((entry) => entry.get("keyword") === keyword)
    );
  }, [glossaryEntries, selectedPageAnnotations]);

  const [highlightedKeyword, setHighlightedKeyword] = React.useState

(null);

  const highlightedAnnotation = selectedPageAnnotations.find(
    (a) => a.keyword === highlightedKeyword
  );

  function setInvoice(key, replace = false) {
    if (key) {
      navigate(`/rechnungserklaerer/rechnung/${key}`, { replace });
    } else {
      navigate(`/rechnungserklaerer/rechnung`, { replace });
    }
  }

  function setInvoicePage(key, replace = false) {
    if (key) {
      navigate(`/rechnungserklaerer/rechnung/${invoiceId}/${key}`, {
        replace,
      });
    } else {
      navigate(`/rechnungserklaerer/rechnung/${invoiceId}`, { replace });
    }
  }

  function getGlossarView() {
    return (
      React.createElement(Collapse, {
        activeKey: highlightedKeyword ? [highlightedKeyword] : [],
        onChange: (key) => {
          if (Array.isArray(key)) {
            setHighlightedKeyword(key[key.length - 1]);
          } else {
            setHighlightedKeyword(key);
          }
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 384}}
      
        , selectedPageAnnotations.map((annotation, index, array) => {
          if (
            array.findIndex((a) => a.keyword === annotation.keyword) !== index
          ) {
            return null;
          }

          const entry = selectedPageGlossaryEntries[index];

          if (!entry) {
            return null;
          }

          return (
            React.createElement(Collapse.Panel, {
              key: entry.get("keyword"),
              id: entry.get("keyword"),
              header: 
                React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 412}}
                  , annotation.index, " - "  , entry.get("title")
                )
              ,
              children: 
                React.createElement(React.Fragment, null
                  , React.createElement(GlossaryEntryDetails, { entry: entry, __self: this, __source: {fileName: _jsxFileName, lineNumber: 418}} )
                  , annotation.videoId && (
                    React.createElement(VideoPlayer, { id: annotation.videoId, __self: this, __source: {fileName: _jsxFileName, lineNumber: 420}} )
                  )
                )
              , __self: this, __source: {fileName: _jsxFileName, lineNumber: 408}}
            )
          );
        })
      )
    );
  }

  // select invoice if none is seleceted
  React.useEffect(() => {
    if (!invoiceId && invoices.length > 0) {
      setInvoice(invoices[0].id, true);
    }
  }, [invoices, invoiceId]);

  // handle invoice not found
  React.useEffect(() => {
    if (invoiceId && !selectedInvoice) {
      setInvoice(null, true);
      //message.error(t("app:invoices.error_invoice_not_found"));
    }
  }, [selectedInvoice, invoiceId]);

  // select page if none is seleceted
  React.useEffect(() => {
    if (!pageId && invoicePages.length > 0) {
      setInvoicePage(invoicePages[0].id, true);
    }
  }, [invoicePages, pageId]);

  // handle page not found
  React.useEffect(() => {
    if (pageId && !selectedPage) {
      setInvoicePage(null, true);
      //message.error(t("app:invoices.error_page_not_found"));
    }
  }, [selectedPage, pageId]);

  // handle page change for highlighted keywords
  React.useEffect(() => {
    setHighlightedKeyword(null);
  }, [pageId]);

  return (
    React.createElement(AppLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 467}}
      /* Page Header */
      , React.createElement('div', { style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 469}}
        , React.createElement(Title, { level: 3, __self: this, __source: {fileName: _jsxFileName, lineNumber: 470}}
          , documentLabels
            ? t("app:invoice.document_plural")
            : t("app:invoice.title")
        )
      )
      , React.createElement(ContentContainer, { style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 476}}
        , React.createElement(Row, { style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 477}}
          , React.createElement(Col, {
            xs: { span: 24, order: 2 },
            sm: { span: 24, order: 2 },
            md: { span: 13, order: 1 },
            lg: { span: 15, order: 1 },
            xl: { span: 15, order: 1 },
            style: { width: "100%" },
            className: "paddingClassWithoutPadding", __self: this, __source: {fileName: _jsxFileName, lineNumber: 478}}
          
            , React.createElement(PageContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 487}}
              /* Image View */
              , selectedPage ? (
                React.createElement(PageImageContainer, { ref: imageContainerRef, __self: this, __source: {fileName: _jsxFileName, lineNumber: 490}}
                  , React.createElement('img', {
                    src: _optionalChain([selectedPage, 'access', _10 => _10.get, 'call', _11 => _11("image"), 'optionalAccess', _12 => _12.url, 'call', _13 => _13()]),
                    style: {
                      width: imageContainerSize.width,
                      height: Math.round(imageHeight * imageContainerScale),
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 491}}
                  )

                  , selectedPageAnnotations.map((annotation, index) => {
                    const active = annotation.keyword === highlightedKeyword;
                    const background = active
                      ? "var(--secondaryColor)"
                      : "var(--primaryColor)";
                    const color = active
                      ? "var(--secondaryTextColor)"
                      : "var(--primaryTextColor)";
                    const background_alpha = active
                      ? "var(--secondaryColor-alpha)"
                      : "var(--primaryColor-alpha)";
                    const left = Math.round(annotation.x * imageContainerScale);
                    const top = Math.round(annotation.y * imageContainerScale);

                    const entry = selectedPageGlossaryEntries[index];

                    if (isMobile) {
                      return (
                        React.createElement('span', {
                          key: annotation.key,
                          className: "btntest",
                          style: {
                            background,
                            color,
                            left,
                            top,
                          },
                          onClick: () => {
                            highlightedKeyword == annotation.keyword
                              ? setHighlightedKeyword(null)
                              : (setHighlightedKeyword(annotation.keyword),
                                isMobile
                                  ? setModalStatus(true)
                                  : setModalStatus(false));
                          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 517}}
                        
                          , annotation.index
                        )
                      );
                    } else {
                      return (
                        React.createElement(PageImageAnnotation, {
                          key: annotation.key,
                          title: _optionalChain([entry, 'optionalAccess', _14 => _14.get, 'call', _15 => _15("title")]),
                          style: { left, top },
                          onClick: () => {
                            highlightedKeyword === annotation.keyword
                              ? setHighlightedKeyword(null)
                              : (setHighlightedKeyword(annotation.keyword),
                                _optionalChain([document
, 'optionalAccess', _16 => _16.getElementById, 'call', _17 => _17(annotation.keyword)
, 'optionalAccess', _18 => _18.scrollIntoView, 'call', _19 => _19({
                                    behavior: "smooth",
                                    block: "center",
                                  })]),
                                isMobile
                                  ? setModalStatus(true)
                                  : setModalStatus(false));
                          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 540}}
                        
                          , React.createElement('span', {
                            className: "iconplacer",
                            style: { background, color }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 559}}
                          
                            , annotation.index
                          )
                          , isMobile ? (
                            React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 566}} )
                          ) : (
                            React.createElement('span', {
                              className: 
                                annotation.x > 1240
                                  ? "iconmarkerr"
                                  : "iconmarkerl"
                              ,
                              style: {
                                background: background_alpha,
                                color,
                                transform:
                                  annotation.x > 1240
                                    ? "translateX(-100%)"
                                    : "",
                              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 568}}
                            
                              , _optionalChain([entry, 'optionalAccess', _20 => _20.get, 'call', _21 => _21("title")])
                            )
                          )
                        )
                      );
                    }
                  })
                )
              ) : (
                React.createElement(Empty, {
                  style: {
                    width: "100%",
                    height: "500px",
                    paddingTop: "200px",
                  },
                  description: React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 598}}, t("app:invoice.nopage")), __self: this, __source: {fileName: _jsxFileName, lineNumber: 592}}
                )
              )
            )
          )
          , React.createElement(Col, {
            xs: { span: 24, order: 1 },
            sm: { span: 24, order: 1 },
            md: { span: 11, order: 2 },
            lg: { span: 9, order: 2 },
            xl: { span: 9, order: 3 },
            style: { width: "100%" },
            className: "paddingClass", __self: this, __source: {fileName: _jsxFileName, lineNumber: 603}}
          
            , React.createElement(ExplainationContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 612}}
              /* Invoice Navigation */
              , invoices.length > 1 ? (
                React.createElement('h4', { style: { width: "100%", textAlign: "left" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 615}}
                  , documentLabels
                    ? t("app:invoice.document_plural")
                    : t("app:invoice.invoice")
                )
              ) : (
                React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 621}})
              )
              , invoices.length > 1 ? (
                React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 624}}
                  , _optionalChain([selectedInvoice, 'optionalAccess', _22 => _22.get, 'call', _23 => _23("id")])
                  , invoices.map((invoice) => (
                    React.createElement(Col, {
                      key: invoice.id,
                      onClick: () => {
                        setInvoice(invoice.id);
                      },
                      xs: 8,
                      style: {
                        cursor: "pointer",
                        padding: "5px",
                        height: "110px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 627}}
                    
                      , React.createElement(Row, {
                        justify: "center",
                        align: "middle",
                        style: {
                          borderBottom:
                            _optionalChain([selectedInvoice, 'optionalAccess', _24 => _24.id]) === invoice.id
                              ? "3px solid var(--primaryColor)"
                              : "0px solid black",
                        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 643}}
                      
                        , React.createElement(Col, { span: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 653}}
                          , React.createElement(InvoiceIcon, { invoice: invoice, size: "40px", __self: this, __source: {fileName: _jsxFileName, lineNumber: 654}} )
                        )
                        , React.createElement(Col, {
                          span: 24,
                          style: {
                            lineHeight: "1.1",
                            height: "2.2em",
                            fontSize: "12px",
                          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 656}}
                        
                          , getInvoiceTitle(invoice)
                        )
                      )
                    )
                  ))
                )
              ) : (
                React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 671}})
              )

              /* Invoice Page Navigation */

              , invoicePages.length > 1 ? (
                React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 677}}
                  , React.createElement('h4', { style: { width: "100%", textAlign: "left" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 678}}
                    , documentLabels
                      ? t("app:invoice.page_plural")
                      : t("app:invoice.invoicepages")
                  )
                  , React.createElement('div', {
                    style: {
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 683}}
                  
                    , React.createElement(Pagination, {
                      hideOnSinglePage: true,
                      showSizeChanger: false,
                      responsive: true,
                      pageSize: 1,
                      onChange: (x) => {
                        setInvoicePage(invoicePages[x - 1].id);
                      },
                      defaultCurrent: 1,
                      total: invoicePages.length,
                      style: {
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 691}}
                    )
                  )
                  , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 709}} )
                )
              ) : (
                React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 712}})
              )

              /* 
              <Tabs
                size="large"
                activeKey={pageId}
                onChange={setInvoicePage}
                style={{
                  padding: "5px",
                }}
              >
                {invoicePages.map((page) => (
                  <Tabs.TabPane key={page.id} tab={page.get("title")} />
                ))}
              </Tabs>
*/
              /* Glossary View */

              , selectedPageGlossaryEntries.length > 0 ? (
                React.createElement(Col, { xs: 0, sm: 0, md: 24, lg: 24, xl: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 732}}
                  , React.createElement('h4', { style: { width: "100%", textAlign: "left" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 733}}
                    , t("app:invoice.glossar")
                  )
                  , getGlossarView()
                )
              ) : (
                React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 739}})
              )
            )
          )
          , selectedPageGlossaryEntries.length > 0 ? (
            React.createElement(Col, {
              xs: { span: 24, order: 3 },
              sm: { span: 24, order: 3 },
              md: { span: 0, order: 3 },
              lg: { span: 0, order: 3 },
              xl: { span: 0, order: 3 },
              style: { width: "100%" },
              className: "paddingClass", __self: this, __source: {fileName: _jsxFileName, lineNumber: 744}}
            
              , React.createElement('h4', { style: { width: "100%", textAlign: "left" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 753}}
                , t("app:invoice.glossar")
              )
              , getGlossarView()
            )
          ) : (
            React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 759}})
          )
        )
      )
      , React.createElement(Modal, {
        visible: modalStatus,
        title: _optionalChain([glossaryEntries
, 'access', _25 => _25.find, 'call', _26 => _26((entry) => entry.get("keyword") === highlightedKeyword)
, 'optionalAccess', _27 => _27.get, 'call', _28 => _28("title")]),
        width: 800,
        bodyStyle: { padding: "10px", maxHeight: "80vh", overflow: "scroll" },
        destroyOnClose: true,
        footer: null,
        onCancel: () => {
          setModalStatus(false);
          setHighlightedKeyword(null);
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 763}}
      
        , React.createElement(GlossaryEntryDetails, {
          entry: glossaryEntries.find(
            (entry) => entry.get("keyword") === highlightedKeyword
          ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 777}}
        )
        , _optionalChain([highlightedAnnotation, 'optionalAccess', _29 => _29.videoId]) && (
          React.createElement(VideoPlayer, { id: highlightedAnnotation.videoId, __self: this, __source: {fileName: _jsxFileName, lineNumber: 783}} )
        )
      )
    )
  );
});

import Parse from "parse";













export class TenantDomain extends Parse.Object {
  static __initStatic() {this.className = "APP_TenantDomain"}

  constructor(data) {
    super("APP_TenantDomain", data );
  }

  get domain() {
    return super.get("domain");
  }
  set domain(value) {
    super.set("domain", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
} TenantDomain.__initStatic();

Parse.Object.registerSubclass("APP_TenantDomain", TenantDomain);

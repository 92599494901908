const _jsxFileName = "/home/runner/work/asew-rechnungserklaerer-web/asew-rechnungserklaerer-web/src/js/components/AppProvider.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import * as React from "react";

import styled from "styled-components";
import { app } from "../state";

import { Icon } from "@opendash/icons";

const OuterContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InnerContainer = styled.div`
  max-width: 400px;
  text-align: center;

  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #000;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
`;

const ErrorIcon = styled.div`
  font-size: 40px;
`;

const ErrorTitle = styled.div`
  font-size: 20px;
  font-weight: bold;

  margin-bottom: 10px;
`;

const ErrorDescription = styled.div`
  font-size: 16px;

  .highlight {
    background: rgba(0, 0, 0, 0.1);
    padding: 0 3px;
    border-radius: 3px;
  }
`;

export const AppProvider = React.memo(function AppProvider({ children }) {
  const [loading, setLoading] = React.useState(app.isLoading());

  React.useEffect(() => {
    return app.subscribe(() => {
      setLoading(app.isLoading());
    });
  }, []);

  if (app.errorTenantNotFound) {
    return (
      React.createElement(OuterContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 109}}
        , React.createElement(InnerContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 110}}
          , React.createElement(ErrorIcon, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 111}}
            , React.createElement(Icon, { icon: "fa:exclamation-triangle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 112}} )
          )

          , React.createElement(ErrorTitle, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 115}}, "Es ist ein Fehler aufgetreten"    )

          , React.createElement(ErrorDescription, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 117}}, "Für die Domain"
              , " "
            , React.createElement('span', { className: "highlight", __self: this, __source: {fileName: _jsxFileName, lineNumber: 119}}, _optionalChain([window, 'optionalAccess', _ => _.location, 'optionalAccess', _2 => _2.hostname])), " ", "wurde kein Mandant gefunden. Bitte kontaktieren Sie den Betreiber der Webseite."


          )
        )
      )
    );
  }

  if (app.error) {
    return (
      React.createElement(OuterContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 130}}
        , React.createElement(InnerContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 131}}
          , React.createElement(ErrorIcon, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 132}}
            , React.createElement(Icon, { icon: "fa:exclamation-triangle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 133}} )
          )

          , React.createElement(ErrorTitle, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 136}}, "Es ist ein Fehler aufgetreten"    )

          , React.createElement(ErrorDescription, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 138}}, "Es ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es erneut. Falls dieser Fehler wiederholt auftritt, kontaktieren Sie den Betreiber der Webseite."



          )
        )
      )
    );
  }

  if (loading) {
    return (
      React.createElement(OuterContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 150}}
        , React.createElement(InnerContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 151}}
          , React.createElement('div', { className: "lds-ellipsis", __self: this, __source: {fileName: _jsxFileName, lineNumber: 152}}
            , React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 153}})
            , React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 154}})
            , React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 155}})
            , React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 156}})
          )
        )
      )
    );
  }

  return React.createElement(React.Fragment, null, children);
});

const _jsxFileName = "/home/runner/work/asew-rechnungserklaerer-web/asew-rechnungserklaerer-web/src/js/components/HeaderMenuComponent.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import * as React from "react";
import { Menu } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { Icon } from "@opendash/icons";
import { getTitle, useAppState } from "../state";
import { useTranslation } from "@opendash/i18n";

const defaultMenuItems = [
  {
    key: "1",
    label: "app:menu.frontpage",
    path: "/",
  },
  {
    key: "2",
    label: "app:menu.invoice",
    path: "/rechnungserklaerer/rechnung",
  },
  {
    key: "7",
    label: "app:energy_saving_tips.menu",
    path: "/rechnungserklaerer/energiespartipps",
  },
  {
    key: "3",
    label: "app:menu.movies",
    path: "/rechnungserklaerer/filme",
  },
  {
    key: "5",
    label: "app:menu.glossar",
    path: "/rechnungserklaerer/glossar",
  },
  {
    key: "6",
    label: "app:menu.contact",
    path: "/rechnungserklaerer/contact",
  },
];

export const HeaderMenuComponent = React.memo(function HeaderMenuComponent({}) {
  const t = useTranslation();

  const { tenantConfig } = useAppState();

  const [items, setItemMenu] = React.useState(defaultMenuItems);
  const location = useLocation();

  const [selectedKey, setSelectedKey] = React.useState(
    _optionalChain([items, 'access', _ => _.find, 'call', _2 => _2((_item) => location.pathname.startsWith(_item.path)), 'optionalAccess', _3 => _3.key])
  );

  const onClickMenu = (item) => {
    const clicked = items.find((_item) => _item.key === item.key);

    if (_optionalChain([clicked, 'optionalAccess', _4 => _4.path])) {
      const path = clicked.path;

      if (path.startsWith("http://") || path.startsWith("https://")) {
        _optionalChain([window, 'access', _5 => _5.open, 'call', _6 => _6(path, "_blank"), 'optionalAccess', _7 => _7.focus, 'call', _8 => _8()]);
      } else {
        navigate(path || "/");
      }
    }
  };

  React.useEffect(() => {
    if (location.pathname === "/") {
      setSelectedKey("1");
    } else {
      var itemPaths = [...items];

      //if (itemPaths.length > 0 && itemPaths[0].key == "1") {
      itemPaths.shift();
      //}

      setSelectedKey(
        _optionalChain([itemPaths, 'access', _9 => _9.find, 'call', _10 => _10((_item) => location.pathname.startsWith(_item.path)), 'optionalAccess', _11 => _11.key])
      );
    }
  }, [location.pathname]);

  React.useEffect(() => {
    let newitems = [...defaultMenuItems];

    if (_optionalChain([tenantConfig, 'optionalAccess', _12 => _12.get, 'call', _13 => _13("documentLabels")])) {
      for (const item of newitems) {
        if (item.key === "2") {
          item.label = "app:invoice.document_plural";
        }
      }
    }

    if (
      _optionalChain([tenantConfig, 'optionalAccess', _14 => _14.get, 'call', _15 => _15("menuItemLabel")]) &&
      _optionalChain([tenantConfig, 'optionalAccess', _16 => _16.get, 'call', _17 => _17("menuItemUrl")])
    ) {
      newitems.push({
        key: "custom1",
        label: getTitle(_optionalChain([tenantConfig, 'optionalAccess', _18 => _18.get, 'call', _19 => _19("menuItemLabel")]) ),
        path: _optionalChain([tenantConfig, 'optionalAccess', _20 => _20.get, 'call', _21 => _21("menuItemUrl")]) ,
      });
    }

    if (
      _optionalChain([tenantConfig, 'optionalAccess', _22 => _22.get, 'call', _23 => _23("menuItem2Label")]) &&
      _optionalChain([tenantConfig, 'optionalAccess', _24 => _24.get, 'call', _25 => _25("menuItem2Url")])
    ) {
      newitems.push({
        key: "custom2",
        label: getTitle(_optionalChain([tenantConfig, 'optionalAccess', _26 => _26.get, 'call', _27 => _27("menuItem2Label")]) ),
        path: _optionalChain([tenantConfig, 'optionalAccess', _28 => _28.get, 'call', _29 => _29("menuItem2Url")]) ,
      });
    }

    if (
      _optionalChain([tenantConfig, 'optionalAccess', _30 => _30.get, 'call', _31 => _31("menuItem3Label")]) &&
      _optionalChain([tenantConfig, 'optionalAccess', _32 => _32.get, 'call', _33 => _33("menuItem3Url")])
    ) {
      newitems.push({
        key: "custom3",
        label: getTitle(_optionalChain([tenantConfig, 'optionalAccess', _34 => _34.get, 'call', _35 => _35("menuItem3Label")]) ),
        path: _optionalChain([tenantConfig, 'optionalAccess', _36 => _36.get, 'call', _37 => _37("menuItem3Url")]) ,
      });
    }

    if (_optionalChain([tenantConfig, 'optionalAccess', _38 => _38.get, 'call', _39 => _39("hideVideos")])) {
      newitems = newitems.filter((i) => i.key !== "3");
    }

    setItemMenu(newitems);
  }, [tenantConfig]);

  const navigate = useNavigate();

  return (
    React.createElement(Menu, {
      className: "customMenu",
      triggerSubMenuAction: "click",
      selectedKeys: selectedKey ? [selectedKey] : [],
      mode: "horizontal",
      onClick: onClickMenu,
      overflowedIndicator: 
        React.createElement(Icon, { style: { fontSize: "20px", marginTop: "20px" }, icon: "fa:bars", __self: this, __source: {fileName: _jsxFileName, lineNumber: 144}} )
      , __self: this, __source: {fileName: _jsxFileName, lineNumber: 137}}
    
      , items.map((item) => (
        React.createElement(Menu.Item, { key: item.key, __self: this, __source: {fileName: _jsxFileName, lineNumber: 148}}
          , React.createElement('h4', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 149}}, t(item.label))
        )
      ))
    )
  );
});

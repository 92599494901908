import Parse from "parse";
























export class InvoicePage extends Parse.Object {
  static __initStatic() {this.className = "APP_InvoicePage"}

  constructor(data) {
    super("APP_InvoicePage", data );
  }

  get annotations() {
    return super.get("annotations");
  }
  set annotations(value) {
    super.set("annotations", value);
  }
  get deleted() {
    return super.get("deleted");
  }
  set deleted(value) {
    super.set("deleted", value);
  }
  get description() {
    return super.get("description");
  }
  set description(value) {
    super.set("description", value);
  }
  get draft() {
    return super.get("draft");
  }
  set draft(value) {
    super.set("draft", value);
  }
  get image() {
    return super.get("image");
  }
  set image(value) {
    super.set("image", value);
  }
  get imageHeight() {
    return super.get("imageHeight");
  }
  set imageHeight(value) {
    super.set("imageHeight", value);
  }
  get imageWidth() {
    return super.get("imageWidth");
  }
  set imageWidth(value) {
    super.set("imageWidth", value);
  }
  get invoice() {
    return super.get("invoice");
  }
  set invoice(value) {
    super.set("invoice", value);
  }
  get order() {
    return super.get("order");
  }
  set order(value) {
    super.set("order", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
  get title() {
    return super.get("title");
  }
  set title(value) {
    super.set("title", value);
  }
} InvoicePage.__initStatic();

Parse.Object.registerSubclass("APP_InvoicePage", InvoicePage);

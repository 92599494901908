const _jsxFileName = "/home/runner/work/asew-rechnungserklaerer-web/asew-rechnungserklaerer-web/src/js/components/EnergySavingTipsRoute.tsx";import * as React from "react";
import styled from "styled-components";

import { useParams } from "react-router-dom";
import { Typography, message } from "antd";

import { useAppState } from "../state";
import { AppLayout } from "./AppLayout";
import { useTranslation } from "@opendash/i18n";

const tips = new Array(22).fill(0).map((v, index) => index + 1);

const ContentBox = styled.div`
  padding: 20px;
`;

const TipsBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;

  margin-top: 30px;

  font-size: 16px;

  @media only screen and (min-width: 800px) {
    font-size: 18px;
  }
`;

const TipBox = styled.div`
  margin-bottom: 40px;
  width: 100%;

  @media only screen and (min-width: 800px) {
    width: calc(50% - 20px);
  }
`;

const TipHeadBox = styled.div`
  font-weight: bold;
  margin-bottom: 10px;

  @media only screen and (min-width: 800px) {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 20px;
  }
`;

const TipNumberBox = styled.div`
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.1);

  margin-bottom: 6px;

  @media only screen and (min-width: 800px) {
    margin-bottom: 0px;
  }

  @media only screen and (min-width: 1200px) {
    height: 60px;
    width: 60px;
    line-height: 60px;
  }
`;

const TipTitleBox = styled.div`
  @media only screen and (min-width: 800px) {
    line-height: 40px;
  }

  @media only screen and (min-width: 1200px) {
    line-height: 60px;
  }
`;

const TipDescriptionBox = styled.div``;

export const EnergySavingTipsRoute = React.memo(
  function EnergySavingTipsRoute() {
    const t = useTranslation();
    const { videos } = useAppState();

    const { id } = useParams();

    const selectedVideo = videos.find((video) => video.id === id);

    React.useEffect(() => {
      if (id && !selectedVideo) {
        message.error(t("app:videos.error_not_found"));
      }
    }, [id, selectedVideo]);

    return (
      React.createElement(AppLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 105}}
        , React.createElement(ContentBox, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 106}}
          , React.createElement(Typography.Title, { level: 3, __self: this, __source: {fileName: _jsxFileName, lineNumber: 107}}
            , t("app:energy_saving_tips.title")
          )
          , React.createElement(TipsBox, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 110}}
            , tips.map((tip) => {
              const title = t(`app:energy_saving_tips.tip_${tip}_title`);
              const description = t(
                `app:energy_saving_tips.tip_${tip}_description`
              );

              return (
                React.createElement(TipBox, { key: tip, __self: this, __source: {fileName: _jsxFileName, lineNumber: 118}}
                  , React.createElement(TipHeadBox, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 119}}
                    , React.createElement(TipNumberBox, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 120}}, "#", tip)
                    , React.createElement(TipTitleBox, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 121}}, title)
                  )
                  , React.createElement(TipDescriptionBox, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 123}}, description)
                )
              );
            })
          )
        )
      )
    );
  }
);

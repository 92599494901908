const _jsxFileName = "/home/runner/work/asew-rechnungserklaerer-web/asew-rechnungserklaerer-web/src/js/components/FrontpageRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import * as React from "react";

import { useNavigate } from "react-router-dom";
import { getInvoiceTitle, getTitle, useAppState } from "../state";

import { Col, Row } from "antd";

import { useTranslation } from "@opendash/i18n";
import { Icon } from "@opendash/icons";
import styled from "styled-components";
import { AppLayout } from "./AppLayout";
import { InvoiceIcon } from "./InvoiceIcon";

const OuterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  @media only screen and (max-width: 800px) {
    padding-top: 7px;
    padding-bottom: 5px;
  }
`;

export const FrontpageRoute = React.memo(function FrontpageRoute() {
  const app = useAppState();
  const navigate = useNavigate();
  const t = useTranslation();

  const { invoices, invoicePages: invoicePagesAll } = useAppState();
  const { tenantConfig } = useAppState();

  const background = app.frontpageImage;

  return (
    React.createElement(AppLayout, { fullWidth: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
      , React.createElement(OuterContainer, {
        style: 
          background
            ? {
                backgroundImage: `url("${background}")`,
              }
            : undefined
        , __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}
      
        , React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
          , React.createElement(Row, { align: "middle", justify: "center", style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
            , invoices.map((invoice) => {
              return (
                React.createElement('div', {
                  className: "frontboxouter",
                  key: invoice.id,
                  style: {
                    padding: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}
                
                  , React.createElement('div', {
                    className: "frontpageboxbig",
                    onClick: () => {
                      navigate(`/rechnungserklaerer/rechnung/` + invoice.id);
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
                  
                    , React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}
                      , React.createElement(Col, { xs: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 72}}
                        , React.createElement(InvoiceIcon, { invoice: invoice, size: "50px", __self: this, __source: {fileName: _jsxFileName, lineNumber: 73}} )
                      )
                      , React.createElement(Col, { xs: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 75}}
                        , React.createElement('p', {
                          style: {
                            fontSize: "24px",
                          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}
                        
                          , getInvoiceTitle(invoice)
                        )
                      )
                    )
                  )
                )
              );
            })
          )
          , !_optionalChain([tenantConfig, 'optionalAccess', _ => _.get, 'call', _2 => _2("hideFrontpageSecondRow")]) && (
            React.createElement(Row, { align: "middle", justify: "center", style: {}, __self: this, __source: {fileName: _jsxFileName, lineNumber: 91}}
              , React.createElement('div', {
                className: "frontboxouter",
                style: {
                  padding: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 92}}
              
                , React.createElement('div', {
                  className: "frontpagebox",
                  onClick: () => {
                    navigate(`/rechnungserklaerer/glossar`);
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 101}}
                
                  , React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 107}}
                    , React.createElement(Col, { xs: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 108}}
                      , React.createElement(Icon, {
                        style: {
                          fontSize: "40px",
                        },
                        icon: 
                          _optionalChain([tenantConfig, 'optionalAccess', _3 => _3.get, 'call', _4 => _4("frontpageGlossarIcon")]) || "fa:text"
                        , __self: this, __source: {fileName: _jsxFileName, lineNumber: 109}}
                      )
                    )
                    , React.createElement(Col, { xs: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 118}}
                      , React.createElement('p', {
                        style: {
                          fontSize: "22px",
                        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 119}}
                      
                        , t("app:frontpage.glossar")
                      )
                    )
                  )
                )
              )

              , !_optionalChain([tenantConfig, 'optionalAccess', _5 => _5.get, 'call', _6 => _6("hideVideos")]) && (
                React.createElement('div', {
                  className: "frontboxouter",
                  style: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "5px",
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 132}}
                
                  , React.createElement('div', {
                    className: "frontpagebox",
                    onClick: () => {
                      navigate(`/rechnungserklaerer/filme`);
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 141}}
                  
                    , React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 147}}
                      , React.createElement(Col, { xs: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 148}}
                        , React.createElement(Icon, {
                          style: {
                            fontSize: "40px",
                          },
                          icon: 
                            _optionalChain([tenantConfig, 'optionalAccess', _7 => _7.get, 'call', _8 => _8("frontpageVideoIcon")]) ||
                            "fa:video"
                          , __self: this, __source: {fileName: _jsxFileName, lineNumber: 149}}
                        )
                      )
                      , React.createElement(Col, { xs: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 159}}
                        , React.createElement('p', {
                          style: {
                            fontSize: "22px",
                          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 160}}
                        
                          , t("app:frontpage.movies")
                        )
                      )
                    )
                  )
                )
              )

              , _optionalChain([tenantConfig, 'optionalAccess', _9 => _9.get, 'call', _10 => _10("menuItemLabel")]) && (
                React.createElement('div', {
                  className: "frontboxouter",
                  style: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "5px",
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 174}}
                
                  , React.createElement('div', {
                    className: "frontpagebox",
                    onClick: () => {
                      _optionalChain([window
, 'optionalAccess', _11 => _11.open, 'call', _12 => _12(_optionalChain([tenantConfig, 'optionalAccess', _13 => _13.get, 'call', _14 => _14("menuItemUrl")]), "_blank")
, 'optionalAccess', _15 => _15.focus, 'call', _16 => _16()]);
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 183}}
                  
                    , React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 191}}
                      , React.createElement(Col, { xs: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 192}}
                        , React.createElement(Icon, {
                          style: {
                            fontSize: "40px",
                          },
                          icon: _optionalChain([tenantConfig, 'optionalAccess', _17 => _17.get, 'call', _18 => _18("menuItemIcon")]) || "fa:link", __self: this, __source: {fileName: _jsxFileName, lineNumber: 193}}
                        )
                      )
                      , React.createElement(Col, { xs: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 200}}
                        , React.createElement('p', {
                          style: {
                            fontSize: "22px",
                          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 201}}
                        
                          , getTitle(_optionalChain([tenantConfig, 'optionalAccess', _19 => _19.get, 'call', _20 => _20("menuItemLabel")]))
                        )
                      )
                    )
                  )
                )
              )

              , _optionalChain([tenantConfig, 'optionalAccess', _21 => _21.get, 'call', _22 => _22("menuItem2Label")]) && (
                React.createElement('div', {
                  className: "frontboxouter",
                  style: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "5px",
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 215}}
                
                  , React.createElement('div', {
                    className: "frontpagebox",
                    onClick: () => {
                      _optionalChain([window
, 'optionalAccess', _23 => _23.open, 'call', _24 => _24(_optionalChain([tenantConfig, 'optionalAccess', _25 => _25.get, 'call', _26 => _26("menuItem2Url")]), "_blank")
, 'optionalAccess', _27 => _27.focus, 'call', _28 => _28()]);
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 224}}
                  
                    , React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 232}}
                      , React.createElement(Col, { xs: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 233}}
                        , React.createElement(Icon, {
                          style: {
                            fontSize: "40px",
                          },
                          icon: _optionalChain([tenantConfig, 'optionalAccess', _29 => _29.get, 'call', _30 => _30("menuItem2Icon")]) || "fa:link", __self: this, __source: {fileName: _jsxFileName, lineNumber: 234}}
                        )
                      )
                      , React.createElement(Col, { xs: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 241}}
                        , React.createElement('p', {
                          style: {
                            fontSize: "22px",
                          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 242}}
                        
                          , getTitle(_optionalChain([tenantConfig, 'optionalAccess', _31 => _31.get, 'call', _32 => _32("menuItem2Label")]))
                        )
                      )
                    )
                  )
                )
              )

              , _optionalChain([tenantConfig, 'optionalAccess', _33 => _33.get, 'call', _34 => _34("menuItem3Label")]) && (
                React.createElement('div', {
                  className: "frontboxouter",
                  style: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "5px",
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 256}}
                
                  , React.createElement('div', {
                    className: "frontpagebox",
                    onClick: () => {
                      _optionalChain([window
, 'optionalAccess', _35 => _35.open, 'call', _36 => _36(_optionalChain([tenantConfig, 'optionalAccess', _37 => _37.get, 'call', _38 => _38("menuItem3Url")]), "_blank")
, 'optionalAccess', _39 => _39.focus, 'call', _40 => _40()]);
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 265}}
                  
                    , React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 273}}
                      , React.createElement(Col, { xs: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 274}}
                        , React.createElement(Icon, {
                          style: {
                            fontSize: "40px",
                          },
                          icon: _optionalChain([tenantConfig, 'optionalAccess', _41 => _41.get, 'call', _42 => _42("menuItem3Icon")]) || "fa:link", __self: this, __source: {fileName: _jsxFileName, lineNumber: 275}}
                        )
                      )
                      , React.createElement(Col, { xs: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 282}}
                        , React.createElement('p', {
                          style: {
                            fontSize: "22px",
                          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 283}}
                        
                          , getTitle(_optionalChain([tenantConfig, 'optionalAccess', _43 => _43.get, 'call', _44 => _44("menuItem3Label")]))
                        )
                      )
                    )
                  )
                )
              )

              , React.createElement('div', {
                className: "frontboxouter",
                style: {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "5px",
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 296}}
              
                , React.createElement('div', {
                  className: "frontpagebox",
                  onClick: () => {
                    navigate(`/rechnungserklaerer/contact`);
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 305}}
                
                  , React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 311}}
                    , React.createElement(Col, { xs: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 312}}
                      , React.createElement(Icon, {
                        style: {
                          fontSize: "40px",
                        },
                        icon: 
                          _optionalChain([tenantConfig, 'optionalAccess', _45 => _45.get, 'call', _46 => _46("frontpageContactIcon")]) ||
                          "fa:address-book"
                        , __self: this, __source: {fileName: _jsxFileName, lineNumber: 313}}
                      )
                    )
                    , React.createElement(Col, { xs: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 323}}
                      , React.createElement('p', {
                        style: {
                          fontSize: "22px",
                        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 324}}
                      
                        , t("app:frontpage.contact")
                      )
                    )
                  )
                )
              )
            )
          )
        )
      )
    )
  );
});

 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }










export class LocalUserAdapter  {
  
  

  constructor(options) {
    this.user = _optionalChain([options, 'optionalAccess', _ => _.user]) || {
      id: "anonymous",
      username: "anonymous",
      name: "Anonymous",
      email: "anonymous@openinc.dev",
      session: "xxx",
    };
  }
  onContext(context) {
    this.context = context;

    this.init();
  }

   async init() {
    try {
      this.context.setCurrentUser(this.user);

      this.context.setLoading(false);
      this.context.setValidated(true);
      this.context.setOffline(false);
    } catch (error) {
      console.error(error);
      this.context.setCurrentUser(undefined);

      this.context.setLoading(false);
      this.context.setValidated(false);
      this.context.setOffline(false);
    }
  }

  async login(payload) {
    await this.init();
  }

  async register(payload) {
    await this.init();
  }

  async logout() {
    window.localStorage.clear();
    window.location.reload();
  }
}

import Parse from "parse";


















export class Invoice extends Parse.Object {
  static __initStatic() {this.className = "APP_Invoice"}

  constructor(data) {
    super("APP_Invoice", data );
  }

  get deleted() {
    return super.get("deleted");
  }
  set deleted(value) {
    super.set("deleted", value);
  }
  get description() {
    return super.get("description");
  }
  set description(value) {
    super.set("description", value);
  }
  get draft() {
    return super.get("draft");
  }
  set draft(value) {
    super.set("draft", value);
  }
  get icon() {
    return super.get("icon");
  }
  set icon(value) {
    super.set("icon", value);
  }
  get order() {
    return super.get("order");
  }
  set order(value) {
    super.set("order", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
  get title() {
    return super.get("title");
  }
  set title(value) {
    super.set("title", value);
  }
} Invoice.__initStatic();

Parse.Object.registerSubclass("APP_Invoice", Invoice);

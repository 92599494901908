const _jsxFileName = "/home/runner/work/asew-rechnungserklaerer-web/asew-rechnungserklaerer-web/src/js/components/ContactContent.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import * as React from "react";
import { Descriptions } from "antd";
import { Icon } from "@opendash/icons";
import { useAppState } from "../state";
import ReactMarkdown from "react-markdown";
import { useTranslation } from "@opendash/i18n";
import styled from "styled-components";

const ContactImageElement = styled.img`
  width: 100%;
`;

export const ContactContent = React.memo(function ContactContent() {
  const { tenantConfig } = useAppState();
  const t = useTranslation();

  if (!tenantConfig) {
    return null;
  }

  return (
    React.createElement('div', { style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
      , React.createElement(Descriptions, {
        title: "",
        layout: "vertical",
        bordered: true,
        style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
      
        , React.createElement(Descriptions.Item, {
          label: 
            React.createElement('div', { style: { fontSize: "20px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
              , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
                , React.createElement(Icon, { icon: "fa:address-book", __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}} ), "  "
                , t("app:contact.address")
              )
            )
          ,
          style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
        
          , React.createElement(ReactMarkdown, { children: tenantConfig.get("addressText") || "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}} )
        )
      )

      , React.createElement(Descriptions, {
        title: "",
        layout: "vertical",
        bordered: true,
        style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
      
        , React.createElement(Descriptions.Item, {
          label: 
            React.createElement('div', { style: { fontSize: "20px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
              , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}
                , React.createElement(Icon, { icon: "fa:phone-alt", __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}} ), "  "
                , t("app:contact.phone")
              )
            )
          , __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
        
          , React.createElement(ReactMarkdown, { children: tenantConfig.get("contactText") || "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 60}} )

          , tenantConfig.get("contactWeb") && (
            React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
              , t("app:contact.website"), ":", " "
              , React.createElement('a', { href: tenantConfig.get("contactWeb"), target: "_blank", __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
                , tenantConfig.get("contactWeb")
              )
            )
          )

          , tenantConfig.get("contactPhone") && (
            React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 72}}
              , t("app:contact.phoneentry"), ":", " "
              , React.createElement('a', { href: "tel:" + tenantConfig.get("contactPhone"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 74}}
                , tenantConfig.get("contactPhone")
              )
            )
          )

          , tenantConfig.get("contactWhatsappLabel") &&
            tenantConfig.get("contactWhatsappLink") && (
              React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 82}}
                , t("app:contact.whatsapp"), ":", " "
                , React.createElement('a', {
                  href: tenantConfig.get("contactWhatsappLink"),
                  target: "_blank", __self: this, __source: {fileName: _jsxFileName, lineNumber: 84}}
                
                  , tenantConfig.get("contactWhatsappLabel")
                )
              )
            )

          , tenantConfig.get("contactFax") && (
            React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 94}}
              , t("app:contact.faxentry"), ": " , tenantConfig.get("contactFax")
            )
          )

          , tenantConfig.get("contactEmail") && (
            React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 100}}
              , t("app:contact.mailentry"), ":", " "
              , React.createElement('a', { href: "mailto:" + tenantConfig.get("contactEmail"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 102}}
                , tenantConfig.get("contactEmail")
              )
            )
          )

          , tenantConfig.get("contactImage") && (
            React.createElement(ContactImageElement, {
              src: _optionalChain([tenantConfig, 'access', _ => _.get, 'call', _2 => _2("contactImage"), 'optionalAccess', _3 => _3.url, 'call', _4 => _4()]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 109}}
            )
          )
        )
      )
      , React.createElement(Descriptions, {
        title: "",
        layout: "vertical",
        bordered: true,
        style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 115}}
      
        , React.createElement(Descriptions.Item, {
          label: 
            React.createElement('div', { style: { fontSize: "20px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 123}}
              , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 124}}
                , React.createElement(Icon, { icon: "fa:clock", __self: this, __source: {fileName: _jsxFileName, lineNumber: 125}} ), "  "
                , t("app:contact.opening")
              )
            )
          , __self: this, __source: {fileName: _jsxFileName, lineNumber: 121}}
        
          , React.createElement(ReactMarkdown, {
            children: tenantConfig.get("openingHoursText") || "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 131}}
          )
        )
      )
      , (!!tenantConfig.get("googleMaps") ||
        !!tenantConfig.get("routeDescriptionText")) && (
        React.createElement(Descriptions, {
          title: "",
          layout: "vertical",
          bordered: true,
          style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 138}}
        
          , React.createElement(Descriptions.Item, {
            label: 
              React.createElement('div', { style: { fontSize: "20px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 146}}
                , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 147}}
                  , React.createElement(Icon, { icon: "fa:route", __self: this, __source: {fileName: _jsxFileName, lineNumber: 148}} ), "  "
                  , t("app:contact.route")
                )
              )
            , __self: this, __source: {fileName: _jsxFileName, lineNumber: 144}}
          
            , React.createElement(ReactMarkdown, {
              children: tenantConfig.get("routeDescriptionText") || "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 154}}
            )
            /* 
          <pre>
            {JSON.stringify(
              {
                _: "TODO: Map richtig einbinden!",
                point: tenantConfig.get("mapGeoPoint"),
                label: tenantConfig.get("mapLabel"),
              },
              null,
              2
            )}
            </pre>*/

            , React.createElement('div', { style: { height: "400px", width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 170}}
              , React.createElement('div', { style: { margin: "0 -24px -16px -24px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 171}}
                , React.createElement('iframe', {
                  height: "400",
                  style: { border: "0", width: "100%" },
                  loading: "lazy",
                  src: tenantConfig.get("googleMaps"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 172}}
                )

                /* 
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d644528.425551093!2d6.8925203741342775!3d50.87395406252683!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x47bf3ad30d7896cf%3A0x11c79d2632d441d!2sasew%20eupener%20stra%C3%9Fe%20gps!3m2!1d50.941019999999995!2d6.8909199999999995!5e0!3m2!1sde!2sde!4v1629450173025!5m2!1sde!2sde"
                height="400"
                style={{ border: "0", width: "100%" }}
                loading="lazy"
              />
*/
              )
            )
          )
        )
      )
    )
  );
});

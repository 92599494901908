const _jsxFileName = "/home/runner/work/asew-rechnungserklaerer-web/asew-rechnungserklaerer-web/src/js/components/GlossaryEntryDetails.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { Modal } from "antd";
import * as React from "react";
import ReactMarkdown from "react-markdown";

import { useAppState } from "../state";






const charBlocklist = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
  "[",
  "]",
];

// let count = 0;

export const GlossaryEntryDetails = React.memo(
  function GlossaryEntryDetails({ entry }) {
    const state = useAppState();
    const [details, setDetails] = React.useState(null);
    const md = React.useMemo(() => {
      if (!entry || !entry.get("text")) {
        return "";
      }

      let text = entry.get("text") ;
      let textLowerCase = text.toLowerCase();

      const allKeywordsByLength = Object.keys(
        state.gloassryEntriesMapByTag
      ).sort((a, b) => {
        return b.length - a.length;
      });

      // for (const [keyword, e] of Object.entries(
      //   state.gloassryEntriesMapByTag
      // )) {
      for (const keyword of allKeywordsByLength) {
        const e = state.gloassryEntriesMapByTag[keyword];

        if (e.id === entry.id) {
          continue;
        }

        const keywordLowerCase = keyword.toLowerCase();

        let startIndex = 0;
        let index;

        while (
          (index = textLowerCase.indexOf(keywordLowerCase, startIndex)) > -1
        ) {
          const charBefore = text.charAt(index - 1);
          const charAfter = text.charAt(index + keyword.length);
          const isMatch =
            !charBlocklist.includes(charBefore) &&
            !charBlocklist.includes(charAfter);

          if (isMatch) {
            const match = text.slice(index, index + keyword.length);
            const replacement = `[${match}](/rechnungserklaerer/glossar/${e.id})`;

            text =
              text.slice(0, index) +
              replacement +
              text.slice(index + keyword.length);

            textLowerCase = text.toLowerCase();

            startIndex = index + replacement.length;
          } else {
            startIndex = index + keyword.length;
          }
        }
      }

      return text;
    }, [_optionalChain([entry, 'optionalAccess', _ => _.id])]);
    if (!entry) {
      return null;
    }

    return (
      React.createElement('div', { style: { fontWeight: "normal" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 138}}
        , React.createElement(ReactMarkdown, {
          children: md,
          components: {
            a({ children, href }) {
              if (_optionalChain([href, 'optionalAccess', _2 => _2.startsWith, 'call', _3 => _3("/rechnungserklaerer/glossar/")])) {
                const id = href.replace("/rechnungserklaerer/glossar/", "");
                const detailEntry = state.glossaryEntriesMapById[id];

                return (
                  React.createElement('a', {
                    children: children,
                    href: href,
                    onClick: (e) => {
                      e.preventDefault();
                      setDetails(detailEntry);
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 148}}
                  )
                );
              }
              return React.createElement('a', { href: href, children: children, __self: this, __source: {fileName: _jsxFileName, lineNumber: 158}} );
            },
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 139}}
        )

        , React.createElement(Modal, {
          title: _optionalChain([details, 'optionalAccess', _4 => _4.get, 'call', _5 => _5("title")]),
          visible: !!details,
          onCancel: () => {
            setDetails(null);
          },
          footer: false, __self: this, __source: {fileName: _jsxFileName, lineNumber: 163}}
        
          , details && React.createElement(GlossaryEntryDetails, { entry: details, __self: this, __source: {fileName: _jsxFileName, lineNumber: 171}} )
        )
      )
    );
  }
);

const _jsxFileName = "/home/runner/work/asew-rechnungserklaerer-web/asew-rechnungserklaerer-web/src/js/components/InvoiceIcon.tsx";import { Icon } from "@opendash/icons";
import * as React from "react";






const icon = require("../../assets/invoice-fernwaerme.png");

export const InvoiceIcon = React.memo(function InvoiceIcon({
  invoice,
  size,
}) {
  // if (invoice.id === "LwADNYouRa") {
  //   return <img src={icon} width={size} height={size} />;
  // }

  return (
    React.createElement(Icon, {
      style: {
        fontSize: size,
      },
      icon: invoice.get("icon") || "fa:file", __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
    )
  );
});

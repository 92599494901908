const _jsxFileName = "/home/runner/work/asew-rechnungserklaerer-web/asew-rechnungserklaerer-web/src/js/components/VideoPlayer.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import * as React from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";

import { useAppState } from "../state";

const ReactPlayerContainer = styled.div`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */

  > div {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const VideoPlayer = React.memo(function VideoPlayer({
  id,
}) {
  const { videos } = useAppState();

  const video = videos.find((video) => video.id === id);

  if (!video) {
    return null;
  }

  const url = _optionalChain([video, 'access', _ => _.get, 'call', _2 => _2("video"), 'optionalAccess', _3 => _3.url, 'call', _4 => _4()]);
  const thumbnail = _optionalChain([video, 'access', _5 => _5.get, 'call', _6 => _6("image"), 'optionalAccess', _7 => _7.url, 'call', _8 => _8()]);

  if (!url || !thumbnail) {
    return null;
  }

  return (
    React.createElement(ReactPlayerContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}
      , React.createElement(ReactPlayer, {
        controls: true,
        playing: true,
        volume: 1,
        muted: true,
        width: "100%",
        height: "100%",
        url: url,
        light: thumbnail, __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
      )
    )
  );
});

import Parse from "parse";
































































export class TenantConfig extends Parse.Object {
  static __initStatic() {this.className = "APP_TenantConfig"}

  constructor(data) {
    super("APP_TenantConfig", data );
  }

  get addressText() {
    return super.get("addressText");
  }
  set addressText(value) {
    super.set("addressText", value);
  }
  get contactEmail() {
    return super.get("contactEmail");
  }
  set contactEmail(value) {
    super.set("contactEmail", value);
  }
  get contactFax() {
    return super.get("contactFax");
  }
  set contactFax(value) {
    super.set("contactFax", value);
  }
  get contactImage() {
    return super.get("contactImage");
  }
  set contactImage(value) {
    super.set("contactImage", value);
  }
  get contactPhone() {
    return super.get("contactPhone");
  }
  set contactPhone(value) {
    super.set("contactPhone", value);
  }
  get contactText() {
    return super.get("contactText");
  }
  set contactText(value) {
    super.set("contactText", value);
  }
  get contactWeb() {
    return super.get("contactWeb");
  }
  set contactWeb(value) {
    super.set("contactWeb", value);
  }
  get contactWhatsappLabel() {
    return super.get("contactWhatsappLabel");
  }
  set contactWhatsappLabel(value) {
    super.set("contactWhatsappLabel", value);
  }
  get contactWhatsappLink() {
    return super.get("contactWhatsappLink");
  }
  set contactWhatsappLink(value) {
    super.set("contactWhatsappLink", value);
  }
  get documentLabels() {
    return super.get("documentLabels");
  }
  set documentLabels(value) {
    super.set("documentLabels", value);
  }
  get forceHost() {
    return super.get("forceHost");
  }
  set forceHost(value) {
    super.set("forceHost", value);
  }
  get forceLanguage() {
    return super.get("forceLanguage");
  }
  set forceLanguage(value) {
    super.set("forceLanguage", value);
  }
  get frontpageBackgroundImage() {
    return super.get("frontpageBackgroundImage");
  }
  set frontpageBackgroundImage(value) {
    super.set("frontpageBackgroundImage", value);
  }
  get frontpageContactIcon() {
    return super.get("frontpageContactIcon");
  }
  set frontpageContactIcon(value) {
    super.set("frontpageContactIcon", value);
  }
  get frontpageGlossarIcon() {
    return super.get("frontpageGlossarIcon");
  }
  set frontpageGlossarIcon(value) {
    super.set("frontpageGlossarIcon", value);
  }
  get frontpageVideoIcon() {
    return super.get("frontpageVideoIcon");
  }
  set frontpageVideoIcon(value) {
    super.set("frontpageVideoIcon", value);
  }
  get googleMaps() {
    return super.get("googleMaps");
  }
  set googleMaps(value) {
    super.set("googleMaps", value);
  }
  get hideFrontpageSecondRow() {
    return super.get("hideFrontpageSecondRow");
  }
  set hideFrontpageSecondRow(value) {
    super.set("hideFrontpageSecondRow", value);
  }
  get hideVideos() {
    return super.get("hideVideos");
  }
  set hideVideos(value) {
    super.set("hideVideos", value);
  }
  get imprintText() {
    return super.get("imprintText");
  }
  set imprintText(value) {
    super.set("imprintText", value);
  }
  get imprintUrl() {
    return super.get("imprintUrl");
  }
  set imprintUrl(value) {
    super.set("imprintUrl", value);
  }
  get logo() {
    return super.get("logo");
  }
  set logo(value) {
    super.set("logo", value);
  }
  get logoUrl() {
    return super.get("logoUrl");
  }
  set logoUrl(value) {
    super.set("logoUrl", value);
  }
  get mapGeoPoint() {
    return super.get("mapGeoPoint");
  }
  set mapGeoPoint(value) {
    super.set("mapGeoPoint", value);
  }
  get mapLabel() {
    return super.get("mapLabel");
  }
  set mapLabel(value) {
    super.set("mapLabel", value);
  }
  get menuItem2Icon() {
    return super.get("menuItem2Icon");
  }
  set menuItem2Icon(value) {
    super.set("menuItem2Icon", value);
  }
  get menuItem2Label() {
    return super.get("menuItem2Label");
  }
  set menuItem2Label(value) {
    super.set("menuItem2Label", value);
  }
  get menuItem2Url() {
    return super.get("menuItem2Url");
  }
  set menuItem2Url(value) {
    super.set("menuItem2Url", value);
  }
  get menuItem3Icon() {
    return super.get("menuItem3Icon");
  }
  set menuItem3Icon(value) {
    super.set("menuItem3Icon", value);
  }
  get menuItem3Label() {
    return super.get("menuItem3Label");
  }
  set menuItem3Label(value) {
    super.set("menuItem3Label", value);
  }
  get menuItem3Url() {
    return super.get("menuItem3Url");
  }
  set menuItem3Url(value) {
    super.set("menuItem3Url", value);
  }
  get menuItemIcon() {
    return super.get("menuItemIcon");
  }
  set menuItemIcon(value) {
    super.set("menuItemIcon", value);
  }
  get menuItemLabel() {
    return super.get("menuItemLabel");
  }
  set menuItemLabel(value) {
    super.set("menuItemLabel", value);
  }
  get menuItemUrl() {
    return super.get("menuItemUrl");
  }
  set menuItemUrl(value) {
    super.set("menuItemUrl", value);
  }
  get openingHoursText() {
    return super.get("openingHoursText");
  }
  set openingHoursText(value) {
    super.set("openingHoursText", value);
  }
  get pageTitleContact() {
    return super.get("pageTitleContact");
  }
  set pageTitleContact(value) {
    super.set("pageTitleContact", value);
  }
  get pageTitleGlossary() {
    return super.get("pageTitleGlossary");
  }
  set pageTitleGlossary(value) {
    super.set("pageTitleGlossary", value);
  }
  get pageTitleInvoice() {
    return super.get("pageTitleInvoice");
  }
  set pageTitleInvoice(value) {
    super.set("pageTitleInvoice", value);
  }
  get pageTitleVideo() {
    return super.get("pageTitleVideo");
  }
  set pageTitleVideo(value) {
    super.set("pageTitleVideo", value);
  }
  get primaryColor() {
    return super.get("primaryColor");
  }
  set primaryColor(value) {
    super.set("primaryColor", value);
  }
  get primaryTextColor() {
    return super.get("primaryTextColor");
  }
  set primaryTextColor(value) {
    super.set("primaryTextColor", value);
  }
  get privacyText() {
    return super.get("privacyText");
  }
  set privacyText(value) {
    super.set("privacyText", value);
  }
  get privacyUrl() {
    return super.get("privacyUrl");
  }
  set privacyUrl(value) {
    super.set("privacyUrl", value);
  }
  get routeDescriptionText() {
    return super.get("routeDescriptionText");
  }
  set routeDescriptionText(value) {
    super.set("routeDescriptionText", value);
  }
  get secondaryColor() {
    return super.get("secondaryColor");
  }
  set secondaryColor(value) {
    super.set("secondaryColor", value);
  }
  get secondaryTextColor() {
    return super.get("secondaryTextColor");
  }
  set secondaryTextColor(value) {
    super.set("secondaryTextColor", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
  get title() {
    return super.get("title");
  }
  set title(value) {
    super.set("title", value);
  }
  get tosText() {
    return super.get("tosText");
  }
  set tosText(value) {
    super.set("tosText", value);
  }
  get tosUrl() {
    return super.get("tosUrl");
  }
  set tosUrl(value) {
    super.set("tosUrl", value);
  }
  get hideVideoIds() {
    return super.get("hideVideoIds") || [];
  }
  set hideVideoIds(value) {
    super.set("hideVideoIds", value);
  }



} TenantConfig.__initStatic();

Parse.Object.registerSubclass("APP_TenantConfig", TenantConfig);

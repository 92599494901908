const _jsxFileName = "/home/runner/work/asew-rechnungserklaerer-web/asew-rechnungserklaerer-web/src/js/components/VideoRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import * as React from "react";
import ReactMarkdown from "react-markdown";
import ReactPlayer from "react-player";
import styled from "styled-components";

import { useNavigate, useParams } from "react-router-dom";
import { Modal, Typography, message, Card, Row, Col } from "antd";

import { useAppState } from "../state";
import { AppLayout } from "./AppLayout";
import { useTranslation } from "@opendash/i18n";

const { Meta } = Card;
const { Title } = Typography;

const ReactPlayerContainer = styled.div`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */

  > div {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const VideoRoute = React.memo(function VideoRoute() {
  const t = useTranslation();
  const { videos } = useAppState();

  const { id } = useParams();
  const navigate = useNavigate();

  const selectedVideo = videos.find((video) => video.id === id);

  React.useEffect(() => {
    if (id && !selectedVideo) {
      message.error(t("app:videos.error_not_found"));
    }
  }, [id, selectedVideo]);

  return (
    React.createElement(AppLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}
      , React.createElement(React.Fragment, null
        /* Page Header */
        , React.createElement('div', { style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
          , React.createElement(Title, { level: 3, __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}, t("app:movies.title"))
        )
        /* List of videos: */
        , React.createElement('div', { style: { padding: "0px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
          , React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
            , videos.map((video) => (
              React.createElement(Col, {
                key: video.id,
                xs: { span: 24 },
                sm: { span: 24 },
                md: { span: 12 },
                lg: { span: 6 },
                xl: { span: 6 },
                style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}
              
                , React.createElement(Card, {
                  hoverable: true,
                  key: video.id,
                  style: { width: "100%", height: "340px" },
                  onClick: () => {
                    navigate(`/rechnungserklaerer/filme/${video.id}`);
                  },
                  cover: 
                    React.createElement('img', {
                      style: { height: "213px" },
                      src: video.get("image") ? _optionalChain([video, 'access', _ => _.get, 'call', _2 => _2("image"), 'optionalAccess', _3 => _3.url, 'call', _4 => _4()]) : "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
                    )
                  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
                
                  , React.createElement(Meta, {
                    title: 
                      React.createElement('h4', { style: { fontSize: "15px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}, video.get("title"))
                    ,
                    description: 
                      React.createElement('p', { style: { fontSize: "13px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}
                        , video.get("description")
                      )
                    , __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}
                  )
                )
              )
            ))
          )
        )

        /* Show modal of selected video */
        , React.createElement(Modal, {
          visible: !!selectedVideo,
          title: _optionalChain([selectedVideo, 'optionalAccess', _5 => _5.get, 'call', _6 => _6("title")]),
          width: 1280,
          bodyStyle: { padding: 0 },
          style: { top: 100 },
          destroyOnClose: true,
          footer: null,
          onCancel: () => {
            navigate(`/rechnungserklaerer/filme`);
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 93}}
        
          , selectedVideo && (
            React.createElement(React.Fragment, null
              , React.createElement(ReactPlayerContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 107}}
                , React.createElement(ReactPlayer, {
                  controls: true,
                  playing: true,
                  volume: 1,
                  muted: true,
                  width: "100%",
                  height: "100%",
                  url: selectedVideo.get("video").url(), __self: this, __source: {fileName: _jsxFileName, lineNumber: 108}}
                )
              )

              , React.createElement('div', { style: { padding: 24 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 119}}
                , React.createElement(ReactMarkdown, { children: selectedVideo.get("text") || "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 120}} )
              )
            )
          )
        )
      )
    )
  );
});

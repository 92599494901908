import Parse from "parse";




















export class GlossaryEntry extends Parse.Object {
  static __initStatic() {this.className = "APP_GlossaryEntry"}

  constructor(data) {
    super("APP_GlossaryEntry", data );
  }

  get keyword() {
    return super.get("keyword");
  }
  set keyword(value) {
    super.set("keyword", value);
  }
  get language() {
    return super.get("language");
  }
  set language(value) {
    super.set("language", value);
  }
  get markdown() {
    return super.get("markdown");
  }
  set markdown(value) {
    super.set("markdown", value);
  }
  get tags() {
    return super.get("tags");
  }
  set tags(value) {
    super.set("tags", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
  get text() {
    return super.get("text");
  }
  set text(value) {
    super.set("text", value);
  }
  get title() {
    return super.get("title");
  }
  set title(value) {
    super.set("title", value);
  }
} GlossaryEntry.__initStatic();

Parse.Object.registerSubclass("APP_GlossaryEntry", GlossaryEntry);

const _jsxFileName = "/home/runner/work/asew-rechnungserklaerer-web/asew-rechnungserklaerer-web/src/js/components/FooterComponent.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import * as React from "react";

import { Affix, Avatar, Tooltip, Modal, Button, Space } from "antd";
import { Icon } from "@opendash/icons";
import { ContactContent } from "./ContactContent";
import { LanguageControls } from "./LanguageControls";
import { useAppState } from "../state";
import { useTranslation } from "@opendash/i18n";
import { Link } from "react-router-dom";



export const FooterComponent = React.memo(function FooterComponent({}) {
  const t = useTranslation();
  const state = useAppState();
  const [modalStatus, setModalStatus] = React.useState(false);

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Space, { direction: "vertical", __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
        , React.createElement(LanguageControls, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 21}} )
        , React.createElement(Space, { direction: "horizontal", __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
          , _optionalChain([state, 'access', _ => _.tenantConfig, 'optionalAccess', _2 => _2.get, 'call', _3 => _3("imprintUrl")]) ? (
            React.createElement('a', {
              href: _optionalChain([state, 'access', _4 => _4.tenantConfig, 'optionalAccess', _5 => _5.get, 'call', _6 => _6("imprintUrl")]),
              target: "_blank",
              rel: "noopener noreferrer" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}
            
              , t("app:footer.imprint")
            )
          ) : _optionalChain([state, 'access', _7 => _7.tenantConfig, 'optionalAccess', _8 => _8.get, 'call', _9 => _9("imprintText")]) ? (
            React.createElement(Link, { to: "/impressum", children: t("app:footer.imprint"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}} )
          ) : null

          , _optionalChain([state, 'access', _10 => _10.tenantConfig, 'optionalAccess', _11 => _11.get, 'call', _12 => _12("tosUrl")]) ? (
            React.createElement('a', {
              href: _optionalChain([state, 'access', _13 => _13.tenantConfig, 'optionalAccess', _14 => _14.get, 'call', _15 => _15("tosUrl")]),
              target: "_blank",
              rel: "noopener noreferrer" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}
            
              , t("app:footer.tos")
            )
          ) : _optionalChain([state, 'access', _16 => _16.tenantConfig, 'optionalAccess', _17 => _17.get, 'call', _18 => _18("tosText")]) ? (
            React.createElement(Link, { to: "/agb", children: t("app:footer.tos"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}} )
          ) : null

          , _optionalChain([state, 'access', _19 => _19.tenantConfig, 'optionalAccess', _20 => _20.get, 'call', _21 => _21("privacyUrl")]) ? (
            React.createElement('a', {
              href: _optionalChain([state, 'access', _22 => _22.tenantConfig, 'optionalAccess', _23 => _23.get, 'call', _24 => _24("privacyUrl")]),
              target: "_blank",
              rel: "noopener noreferrer" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
            
              , t("app:footer.privacy")
            )
          ) : _optionalChain([state, 'access', _25 => _25.tenantConfig, 'optionalAccess', _26 => _26.get, 'call', _27 => _27("privacyText")]) ? (
            React.createElement(Link, { to: "/datenschutz", children: t("app:footer.privacy"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}} )
          ) : null
        )
      )

      , React.createElement(Tooltip, { title: "Kontakt", __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}
        , React.createElement(Affix, {
          style: {
            float: "right",
            position: "fixed",
            bottom: "40px",
            right: "40px",
            cursor: "pointer",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
        
          , React.createElement(Button, {
            type: "text",
            onClick: () => {
              setModalStatus(true);
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}
          
            , React.createElement(Avatar, {
              size: { xs: 40, sm: 55, md: 55, lg: 55, xl: 64, xxl: 64 },
              className: "contactaffix",
              icon: React.createElement(Icon, { icon: "fa:user-alt", __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}), __self: this, __source: {fileName: _jsxFileName, lineNumber: 77}}
            )
          )
        )
      )

      , React.createElement(Modal, {
        visible: modalStatus,
        title: "Kontakt",
        width: 800,
        bodyStyle: { padding: 0 },
        destroyOnClose: true,
        footer: null,
        onCancel: () => {
          setModalStatus(false);
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 86}}
      
        , React.createElement(ContactContent, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 97}} )
      )
    )
  );
});
